import React from "react";
import { Box, Text, Button, Flex } from "theme-ui";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Scene from "./scene";
import { isBrowser } from "./utils";

const CTA = () => {
    const breakpts = useBreakpoint();

    const isMobile = breakpts["xs"] || breakpts["sm"];

    const onScheduleCall = () => {
        if (!isBrowser()) {
            return;
        }

        const { Calendly } = window;
        Calendly && Calendly.initPopupWidget({url: "https://calendly.com/nullgr4vity/30min?hide_landing_page_details=1"});
        
        return false;
    }

    return (
    <Box id="about" sx={{position: "relative" }}>
        <Box sx={{color: "white", maxWidth: "1024px", mt: [40, 60, 100], mb: [0, null, 300], py: 0, px: [40, 60, 130] }}>
            <Flex sx={{mb: 30, justifyContent: "space-between", alignItems: "center" }}>
                <Box>
                    <Text as="span" sx={{color: "white"}}>null</Text>
                    <Text as="span" sx={{color: "grey"}}>gravity</Text>
                </Box>
                {isMobile && (<Button 
                    onClick={onScheduleCall}
                    sx={{color: "black", bg: "#fff", cursor: "pointer"}}>
                    Discuss your project
                </Button>)}
            </Flex>
            <Box sx={{mb: 50, fontSize: 64, fontWeight: 600, lineHeight: "80px"}}>
                <Text>Where concepts</Text>
                <Text>come alive</Text>
            </Box>
            <Box sx={{fontSize: 20, fontWeight: 400, lineHeight: "40px"}}>Null Gravity is a custom software development, mobile application development and product design company.</Box>
            {!isMobile && (<Button 
                onClick={onScheduleCall}
                sx={{mt: 30, color: "black", bg: "#fff", cursor: "pointer"}}>
                Discuss your project with me
            </Button>)}
        </Box>
        <Box sx={{ 
            position: ["relative", null, "absolute"],
            top: ["unset", null, -50],
            right: ["unset", null, 0],
            width: ["100%", null, 1000],
            height: [300, 500, 700]
        }}
        >
            <Scene />
        </Box> 
    </Box>);
};

export default CTA;
