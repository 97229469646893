import React, { useRef, useEffect, Suspense } from 'react';
import { Canvas, useFrame, useThree, useLoader } from 'react-three-fiber';

import * as THREE from 'three';

import { softShadows } from '@react-three/drei';

// Inject soft shadow shader
softShadows()

// const deg = THREE.Math.degToRad;

const Box = ({pos, scale, castShadow, receiveShadow}) => {
    const mesh = useRef();
  
    useFrame(() => {
        mesh.current.rotation.x = mesh.current.rotation.y += 0.03;
    })
  
    return (
      <mesh ref={mesh} visible scale={scale} position={pos} castShadow={castShadow} receiveShadow={receiveShadow}>
        <boxGeometry attach="geometry" args={[1, 1, 1]} />
        <meshStandardMaterial attach="material" color={'orange'} />
      </mesh>
    )
}

const Plane = ({pos, scale, castShadow, receiveShadow}) => {  
    const tx = useLoader(THREE.TextureLoader, 'qrcode.png');
    return (
      <mesh visible scale={scale} position={pos} castShadow={castShadow} receiveShadow={receiveShadow}>
        <boxGeometry attach="geometry" args={[1, 1, 1]} />
        <meshStandardMaterial attach="material" color={'grey'} />
        <meshStandardMaterial attach="material" map={tx} />
      </mesh>
    )
}

const Ground = ({color = "white", scale=[1,1,1], pos=[0, 0, 0], castShadow, receiveShadow}) => {
    const mesh = useRef();
  
    return (
        <mesh 
            ref={mesh} 
            visible scale={scale} 
            position={pos} 
            rotation={[Math.PI * -.5, 0, 0]} 
            castShadow={castShadow} 
            receiveShadow={receiveShadow}
        >
            <planeGeometry attach="geometry" args={[16, 16]} />
            <meshStandardMaterial attach="material" color={color} />
        </mesh>
    )
}

const Camera = (props) => {
    const ref = useRef()
    const { setDefaultCamera } = useThree();
    // Make the camera known to the system
    useEffect(() => {
        setDefaultCamera(ref.current)
        ref.current.lookAt(0, 0, 0);
    }, [setDefaultCamera]);
    // Update it every frame
    useFrame(() => ref.current.updateMatrixWorld())
    return <perspectiveCamera ref={ref} {...props} />
  }

const Scene = () => {
    return (
        <Canvas 
            onCreated={({ gl }) => gl.setClearColor(0x000000, 0)}
            shadowMap
            colorManagement
        >
            <Camera position={[15, 10, 15]} fov={45}/>
            <ambientLight intensity={0.5} />
            <spotLight
                position={[0, 30, 0]}
                lookAt={[0, 0, 0]}
                intensity={1}
                penumbra={1}
                focus={1}
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                castShadow

                shadow-camera-far={5000}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />

            <Ground receiveShadow />
            <Suspense fallback={null}>
                <Plane scale={[10, 0.05, 10]} pos={[0, 0.05, 0]} castShadow receiveShadow />
            </Suspense>
            <Box scale={[3,3,3]} pos={[0, 5, 0]} castShadow/>
        </Canvas>)    
}


Scene.displayName = "Scene";

export default Scene;