import React from "react";
import { Box, Grid, Text } from "theme-ui"; 
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { currentBreakpoint } from "./utils";

const features = [
    {
        id: 1,
        title: "We provide services",
        content: "to customers from all over the world to accelerate their business’ in the fast-changing world of technology.",
    },
    {
        id: 2,
        title: "We build applications",
        content: "dedicated to the specific needs of our clients and the market. We can build software from scratch, but also implement changes in existing solutions.",
    },
    {
        id: 3,
        title: "Teamwork",
        content: "is the cornerstone of our most impressive projects. Constant hunger for innovative ideas, different skills and personalities. That describes our team of developers and UI/UX designers.",
    }
]

const Feature = ({id, title, content}) => {
    return (
        <Box>
            <Box sx={{bg: "black", color: "white", mb: 3, display: "inline-block", p: "3px", minWidth: 30, textAlign: "center" }}>{`0${id}`}</Box>
            <Text sx={{mb: 3, fontWeight: 400}}>{title}</Text>
            <Text sx={{mb: 3, fontWeight: 300}}>{content}</Text>
        </Box>);
}

const gridColsDef = ["1fr", "1fr", "1fr 1fr 1fr"];
const gridGapsDef = [10, 30, 100];

const AboutUs = () => {
    const codes = useBreakpoint();
    const idx = currentBreakpoint(codes);
    const columns = gridColsDef[idx];
    const gap = gridGapsDef[idx];

    return (<Box sx={{ p: [10, 30, null], boxSizing: "unset", bg: "background", pb: 50, zIndex: 100000 }}>
        <Box sx={{mb: 30, fontWeight: 600, fontSize: 20}}>
            <Text as="span">about</Text><Text as="span" sx={{color: "grey"}}>us</Text>
        </Box>
        <Grid gap={gap} columns={columns}>
            {features.map(f => <Feature key={f.id} {...f} />)}
        </Grid>
    </Box>);
};

export default AboutUs;
