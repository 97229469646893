import React from "react";
import { Box, Image, Grid, Text } from "theme-ui"; 

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { currentBreakpoint } from "./utils";

import IconMobile from "./../assets/spec_mobile.svg";
import IconWeb from "./../assets/spec_desktop.svg";
import IconAR from "./../assets/spec_ar.svg";
import IconCloud from "./../assets/spec_cloud.svg";
import IconNLP from "./../assets/spec_nlp.svg";
import IconCV from "./../assets/spec_nlp.svg";

const specializations = [
    {
        id: "mobile",
        title: "Mobile projects",
        icon: IconMobile,
    },
    {
        id: "web",
        title: "Web projects",
        icon: IconWeb,
    },
    {
        id: "ar",
        title: "AR",
        icon: IconAR,
    },
    {
        id: "cloud",
        title: "Cloud projects",
        icon: IconCloud,
    },
    {
        id: "nlp",
        title: "NLP",
        icon: IconNLP,
    },
    {
        id: "cv",
        title: "Computer Vision",
        icon: IconCV,
    },
]

const Feature = ({title, icon}) => {
    return (
        <Box>
            <Image sx={{width: 32, height: 32}} src={icon}/>
            <Text sx={{mb: 3, fontWeight: 400}}>{title}</Text>
        </Box>);
}

const gridColsDef = ["1fr 1fr", "1fr 1fr 1fr 1fr", "1fr 1fr 1fr 1fr 1fr 1fr"];

const Specialization = () => {
    const codes = useBreakpoint();
    const idx = currentBreakpoint(codes);
    const columns = gridColsDef[idx];

    return (<Box id="specs" sx={{ p: [10, 30, null], boxSizing: "unset", bg: "background", pb: 50 }}>
        <Box sx={{mb: 30, fontWeight: 600, fontSize: 20}}>
            <Text as="span">our</Text><Text as="span" sx={{color: "grey"}}>specialization</Text>
        </Box>
        <Grid columns={columns}>
            {specializations.map(s => <Feature key={s.id} {...s} />)}
        </Grid>
    </Box>);
};

export default Specialization;
