import React from "react";
import { Box, Image, Grid, Text } from "theme-ui"; 

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { currentBreakpoint } from "./utils";

import IconAWS from "./../assets/logo_aws.svg";
import IconAzure from "./../assets/logo_azure.svg";
import IconAndroid from "./../assets/logo_android.svg";
import IconIOS from "./../assets/logo_ios.svg";
import IconCSS from "./../assets/logo_css.svg";
import IconHTML from "./../assets/logo_html.svg";
import IconDocker from "./../assets/logo_docker.svg";
import IconGit from "./../assets/logo_git.svg";
import IconPython from "./../assets/logo_python.svg";
import IconNode from "./../assets/logo_nodejs.svg";
import IconReact from "./../assets/logo_native.svg";
import IconJS from "./../assets/logo_js.svg";
import IconGraphQL from "./../assets/logo_graphql.svg";
import IconKeras from "./../assets/logo_keras.svg";
import IconOpenCV from "./../assets/logo_opencv.svg";

const techs = [
    {
        id: "JavaScript ES6+",
        icon: IconJS,
        url: "http://es6-features.org/"
    },
    {
        id: "Python",
        icon: IconPython,
        url: "https://www.python.org/",
    },
    {
        id: "React",
        icon: IconReact,
        url: "https://reactjs.org/"
    },
    {
        id: "NodeJS",
        icon: IconNode,
        url: "https://nodejs.org/en/"
    },
    {
        id: "GraphQL",
        icon: IconGraphQL,
        url: "https://graphql.org/"
    },
    {
        id: "Aws",
        icon: IconAWS,
        url: "https://aws.amazon.com/"
    },
    {
        id: "Azure",
        icon: IconAzure,
        url: "https://azure.microsoft.com/",
    },
    {
        id: "Keras",
        icon: IconKeras,
        url: "https://keras.io/"
    },
    {
        id: "OpenCV",
        icon: IconOpenCV,
        url: "https://opencv.org/",
    },
    {
        id: "iOS",
        icon: IconIOS,
        url: "https://developer.apple.com/",
    },
    {
        id: "Android",
        icon: IconAndroid,
        url: "https://developer.android.com/",
    },
    {
        id: "CSS3",
        icon: IconCSS,
    },
    {
        id: "HTML5",
        icon: IconHTML,
        url: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    },
    {
        id: "Docker",
        icon: IconDocker,
        url: "https://www.docker.com/"
    },
    {
        id: "Git",
        icon: IconGit,
        url: "https://git-scm.com/"

    },
]

const Feature = ({id, icon, url}) => {
    return (
        <Box as="a" sx={{ width: [32, 64, 64], height: [32, 64, 64], maxWidth: "unset" }} href={url}>
            <Image src={icon} aria-label={id} sx={{ maxHeight: [32, 64, 64] }} />
        </Box>);
}

const gridColsDef = [
    Array(4).fill("1fr").join(" "),
    Array(6).fill("1fr").join(" "),
    Array(10).fill("1fr").join(" ")
];

const Technologies = () => {
    const codes = useBreakpoint();
    const idx = currentBreakpoint(codes);
    const columns = gridColsDef[idx];

    return (<Box id="techs" sx={{ p: [10, 30, null], boxSizing: "unset", bg: "background", pb: 50 }}>
        <Box sx={{mb: 30, fontWeight: 600, fontSize: 20}}>
            <Text as="span">our</Text><Text as="span" sx={{color: "grey"}}>technologies</Text>
        </Box>
        <Grid columns={columns}>
            {techs.map(t => <Feature key={t.id} {...t} />)}
        </Grid>
    </Box>);
};

export default Technologies;
