import React from "react";
import { Box } from "theme-ui";
import Layout from "../components/layout";
import Specialization from "../components/specialization";
import AboutUs from "../components/aboutUs";
import Technolgies from "../components/technologies";
import CTA from "../components/cta";

const IndexPage = () => (
  <Layout>
    <CTA />
    <Box sx={{paddingY: 0, paddingX: [0, 60, 130]}}>
      <Box sx={{p: 50, bg: "background"}}>
        <AboutUs/>
        <Specialization/>
        <Technolgies/>
      </Box>
    </Box>
  </Layout>
)

export default IndexPage
